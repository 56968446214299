import {UserGroup} from '../auth/symbols';

/**
 * List of available role groups provided by backend.
 */
export enum AvailablePermissionGroups {

  /** Physician site role. */
  Physician = 'Physician',

  /** Staff site role. */
  Staff = 'Staff',

  /** Provider site role. */
  Provider = 'Provider',

  /** Clinic Admin site role. */
  ClinicAdmin = 'Clinic Admin',

  /** Imaging Centers site role. */
  ImagingCenter = 'Imaging Centers',
}

/**
 * Provide relation between role name and its boolean indicator.
 */
export type UserGroupsRelation = {[key in AvailablePermissionGroups]?: true};

/**
 * Match assigned user roles provided by backend with declared on the frontend.
 */
export function defineUserRoles(userGroups: UserGroup[]): UserGroupsRelation {
  // Extract all roles declared on the frontend.
  const allFrontendGroups = Object.values(AvailablePermissionGroups);

  // Match extracted roles with provided by backend.
  const relatedFrontendGroups = userGroups.map(groupMetadata => groupMetadata.name).filter(groupName => allFrontendGroups.includes(groupName))

  // Transform array to group defined object.
  const groupsRelation: UserGroupsRelation = {};
  for (const group of relatedFrontendGroups) {
    groupsRelation[group] = true;
  }

  // Provide result as relation between group name and indicator if it is assigned.
  return groupsRelation;
}

