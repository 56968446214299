import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Select} from '@ngxs/store';
import {defineUserRoles} from './permissions.symbols';
import {AuthState} from '../auth/states/auth/auth.state';

import {User} from '../auth/symbols';


@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  @Select(AuthState.getUser) user$: Observable<User>;

  // List of user permissions.
  // Written as local variables that are updated on user changes.
  // Such a way was chosen for optimization purposes (return status in function will cause trigger on every change detection)
  public seeImagingCenterDashboardMenu: boolean;

  public seeInsightsMenuTab: boolean;
  public seeMainDashboardWrap: boolean;
  public seeBottomMobileMenu: boolean;
  public seePersonalDashboardActions: boolean;
  public seeDashboardManageActions: boolean;

  public passPhysicianGuard: boolean;
  public seeScansList: boolean;
  public accessSharedAccounts: boolean;
  public removePatients: boolean;
  public manageClinicUsers: boolean;
  public seeOwnScans: boolean;
  public seePublicScans: boolean;

  public scheduleMri: boolean;
  public seeOrder3DPrint: boolean;
  public order3DPrint: boolean;
  public requestRecords: boolean;

  public displayClinicLogo: boolean;
  public displayCenterLogo: boolean;
  public displayRoleTitle: boolean;

  public uploadAsImagingCenter: boolean;
  public uploadForPatients: boolean;
  public uploadOwnGenetics: boolean;

  /**
   * Contain current text badge for displaying on the site.
   */
  public roleTitleText: string;

  /**
   * Abstracted frontend role (not defined on the backend).
   * Related with access to the dashboard.
   * Assigned when user has no Staff related permissions.
   */
  private userHasGroupPatient = false;

  /**
   * Staff clinic role.
   * Have the same permissions level as Physician.
   */
  private userHasGroupStaff = false;

  /**
   * Provider clinic role.
   * Have the same permissions level as Physician.
   */
  private userHasGroupProvider = false;

  /**
   * Physician clinic role.
   * Related to managing patients.
   */
  private userHasGroupPhysician = false;

  /**
   * Clinic Admin clinic role.
   * Extended Physician role.
   * Related to managing patients & physicians.
   */
  private userHasGroupClinicAdmin = false;

  /**
   * Imaging center role.
   * Related with access to the imaging center dashboard.
   */
  private userHasGroupImagingCenter = false;


  constructor() {
    this.user$.subscribe(user => {
      this.setUserPermissions(user);
      this.updateUserPermissions();
    });
  }


  /**
   * Update local variables that represent user's permissions.
   * Some roles conflict with each other, so assign only one role to the user.
   */
  private setUserPermissions(user: User): void {
    // Reset all permissions before assigning roles.
    this.userHasGroupPatient = false;
    this.userHasGroupStaff = false;
    this.userHasGroupProvider = false;
    this.userHasGroupPhysician = false;
    this.userHasGroupClinicAdmin = false;
    this.userHasGroupImagingCenter = false;

    // No permissions cannot be provided if email not verified.
    if (!user?.is_verified) {
      return;
    }

    // Get all user roles assigned by the backend.
    const assignedUserRoles = defineUserRoles(user.groups);

    // Assign only the first group role entry by defined priority.
    // Assign patient if no group assigned.
    if (assignedUserRoles['Imaging Centers']) {
      this.userHasGroupImagingCenter = true;
    } else if (assignedUserRoles['Clinic Admin']) {
      this.userHasGroupClinicAdmin = true;
    } else if (assignedUserRoles['Physician']) {
      this.userHasGroupPhysician = true;
    } else if (assignedUserRoles['Provider']) {
      this.userHasGroupProvider = true;
    } else if (assignedUserRoles['Staff']) {
      this.userHasGroupStaff = true;
    } else {
      this.userHasGroupPatient = true;
    }
  }


  /**
   * Assign badge and permissions by current user roles.
   */
  private updateUserPermissions(): void {
    if (this.userHasGroupImagingCenter) {
      this.roleTitleText = 'Imaging Center';
      this.assignImagingCenterPermissions();
    }

    if (this.userHasGroupClinicAdmin) {
      this.roleTitleText = 'Clinic Admin';
      this.assignClinicAdminPermissions();
    }

    if (this.userHasGroupPhysician) {
      this.roleTitleText = 'Physician';
      this.assignPhysicianPermissions();
    }

    if (this.userHasGroupProvider) {
      this.roleTitleText = 'Provider';
      this.assignProviderPermissions();
    }

    if (this.userHasGroupStaff) {
      this.roleTitleText = 'Staff';
      this.assignStaffPermissions();
    }

    if (this.userHasGroupPatient) {
      this.roleTitleText = 'Patient';
      this.assignPatientPermissions();
    }
  }


  /**
   * Assign a list of the Imaging Center permissions.
   */
  private assignImagingCenterPermissions(): void {
    this.seeImagingCenterDashboardMenu = true;

    this.seeInsightsMenuTab = false;
    this.seeMainDashboardWrap = false;
    this.seeBottomMobileMenu = false;
    this.seePersonalDashboardActions = false;
    this.seeDashboardManageActions = false;

    this.passPhysicianGuard = false;
    this.seeScansList = false;
    this.seeOwnScans = false;
    this.seePublicScans = false;
    this.accessSharedAccounts = false;
    this.removePatients = false;
    this.manageClinicUsers = false;

    this.scheduleMri = false;
    this.seeOrder3DPrint = false;
    this.order3DPrint = false;
    this.requestRecords = false;

    this.displayClinicLogo = false;
    this.displayCenterLogo = true;
    this.displayRoleTitle = true;

    this.uploadAsImagingCenter = true;
    this.uploadForPatients = false;
    this.uploadOwnGenetics = false;
  }


  /**
   * Assign a list of the Clinic Admin permissions.
   */
  private assignClinicAdminPermissions(): void {
    this.seeImagingCenterDashboardMenu = false;

    this.seeInsightsMenuTab = false;
    this.seeMainDashboardWrap = true;
    this.seeBottomMobileMenu = false;
    this.seePersonalDashboardActions = false;
    this.seeDashboardManageActions = true;

    this.passPhysicianGuard = true;
    this.seeScansList = true;
    this.seeOwnScans = false;
    this.seePublicScans = false;
    this.accessSharedAccounts = true;
    this.removePatients = true;
    this.manageClinicUsers = true;

    this.scheduleMri = false;
    this.seeOrder3DPrint = false;
    this.order3DPrint = false;
    this.requestRecords = false;

    this.displayClinicLogo = true;
    this.displayCenterLogo = false;
    this.displayRoleTitle = true;

    this.uploadAsImagingCenter = false;
    this.uploadForPatients = true;
    this.uploadOwnGenetics = false;
  }


  /**
   * Assign a list of the Physician permissions.
   */
  private assignPhysicianPermissions(): void {
    this.seeImagingCenterDashboardMenu = false;

    this.seeInsightsMenuTab = false;
    this.seeMainDashboardWrap = true;
    this.seeBottomMobileMenu = false;
    this.seePersonalDashboardActions = false;
    this.seeDashboardManageActions = true;

    this.passPhysicianGuard = true;
    this.seeScansList = true;
    this.seeOwnScans = false;
    this.seePublicScans = false;
    this.accessSharedAccounts = true;
    this.removePatients = false;
    this.manageClinicUsers = false;

    this.scheduleMri = false;
    this.seeOrder3DPrint = false;
    this.order3DPrint = false;
    this.requestRecords = false;

    this.displayClinicLogo = true;
    this.displayCenterLogo = false;
    this.displayRoleTitle = true;

    this.uploadAsImagingCenter = false;
    this.uploadForPatients = true;
    this.uploadOwnGenetics = false;
  }


  /**
   * Assign a list of the Provider permissions.
   */
  private assignProviderPermissions(): void {
    this.seeImagingCenterDashboardMenu = false;

    this.seeInsightsMenuTab = false;
    this.seeMainDashboardWrap = true;
    this.seeBottomMobileMenu = false;
    this.seePersonalDashboardActions = false;
    this.seeDashboardManageActions = true;

    this.passPhysicianGuard = true;
    this.seeScansList = true;
    this.seeOwnScans = false;
    this.seePublicScans = false;
    this.accessSharedAccounts = true;
    this.removePatients = false;
    this.manageClinicUsers = false;

    this.scheduleMri = false;
    this.seeOrder3DPrint = false;
    this.order3DPrint = false;
    this.requestRecords = false;

    this.displayClinicLogo = true;
    this.displayCenterLogo = false;
    this.displayRoleTitle = true;

    this.uploadAsImagingCenter = false;
    this.uploadForPatients = true;
    this.uploadOwnGenetics = false;
  }


  /**
   * Assign a list of the Staff permissions.
   */
  private assignStaffPermissions(): void {
    this.seeImagingCenterDashboardMenu = false;

    this.seeInsightsMenuTab = false;
    this.seeMainDashboardWrap = true;
    this.seeBottomMobileMenu = false;
    this.seePersonalDashboardActions = false;
    this.seeDashboardManageActions = true;

    this.passPhysicianGuard = true;
    this.seeScansList = true;
    this.seeOwnScans = false;
    this.seePublicScans = false;
    this.accessSharedAccounts = true;
    this.removePatients = false;
    this.manageClinicUsers = false;

    this.scheduleMri = false;
    this.seeOrder3DPrint = false;
    this.order3DPrint = false;
    this.requestRecords = false;

    this.displayClinicLogo = true;
    this.displayCenterLogo = false;
    this.displayRoleTitle = true;

    this.uploadAsImagingCenter = false;
    this.uploadForPatients = true;
    this.uploadOwnGenetics = false;
  }


  /**
   * Assign a list of the Patient permissions.
   */
  private assignPatientPermissions(): void {
    this.seeImagingCenterDashboardMenu = false;

    this.seeInsightsMenuTab = true;
    this.seeMainDashboardWrap = true;
    this.seeBottomMobileMenu = true;
    this.seePersonalDashboardActions = true;
    this.seeDashboardManageActions = false;

    this.passPhysicianGuard = false;
    this.seeScansList = true;
    this.seeOwnScans = true;
    this.seePublicScans = true;
    this.accessSharedAccounts = false;
    this.removePatients = false;
    this.manageClinicUsers = false;

    this.scheduleMri = true;
    this.seeOrder3DPrint = true;
    this.order3DPrint = true;
    this.requestRecords = true;

    this.displayClinicLogo = true;
    this.displayCenterLogo = false;
    this.displayRoleTitle = false;

    this.uploadAsImagingCenter = false;
    this.uploadForPatients = false;
    this.uploadOwnGenetics = true;
  }
}
